const images = require.context('../../../assets/images/vfx', true);

export const vfx_images = [
  {
    key: 1,
    src: images(`./dr-strange.jpg`),
    alt: 'vfx dr strange'
  },
  {
    key: 2,
    src: images(`./iron-man.jpg`),
    alt: 'vfx iron man'
  },
  {
    key: 3,
    src: images(`./boxing_2.jpg`),
    alt: 'vfx boxing'
  },
  {
    key: 4,
    src: images(`./boxing_3.jpg`),
    alt: 'vfx boxing 2'
  },
  {
    key: 5,
    src: images(`./boxing_7.jpg`),
    alt: 'vfx boxing 3'
  },
  {
    key: 6,
    src: images(`./unfamous.jpg`),
    alt: 'vfx unfamous'
  },
  {
    key: 7,
    src: images(`./fire.jpg`),
    alt: 'vfx fire'
  },
  {
    key: 8,
    src: images(`./eminem-c.jpg`),
    alt: 'vfx eminem'
  },
  {
    key: 9,
    src: images(`./poster_spiderman.jpg`),
    alt: 'poster spiderman'
  },
  {
    key: 10,
    src: images(`./poster_twd.jpg`),
    alt: 'poster the walking dead'
  },
  {
    key: 11,
    src: images(`./poster_star wars.jpg`),
    alt: 'poster star wars'
  },
  {
    key: 12,
    src: images(`./poster_star wars 7.png`),
    alt: 'poster poster star wars 7'
  },
  {
    key: 13,
    src: images(`./poster-mandalorian.jpg`),
    alt: 'poster mandalorian'
  },
  {
    key: 14,
    src: images(`./poster-neymar.jpg`),
    alt: 'poster neymar'
  },
  {
    key: 15,
    src: images(`./black-widow-b.jpg`),
    alt: 'poster black widow'
  },
  {
    key: 16,
    src: images(`./Daenerys-GOT.jpg`),
    alt: 'poster daenerys'
  },
  {
    key: 17,
    src: images(`./Jinx.jpg`),
    alt: 'poster jinx'
  },
  {
    key: 18,
    src: images(`./moon-knigh-2t.jpg`),
    alt: 'poster moon knigh'
  },
  {
    key: 19,
    src: images(`./scarlet-witch.jpg`),
    alt: 'poster scarlet witch'
  }
];
