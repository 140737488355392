export const items = [
  {
    title: 'INICIO',
    url: '/'
  },
  {
    title: 'PORTFOLIO',
    url: '/portfolio'
    // submenu: [
    //   {
    //     title: 'EVENTOS',
    //     url: '/portfolio/events'
    //   },
    //   {
    //     title: 'RETRATOS',
    //     url: '/portfolio/portrait'
    //   }
    // ]
  },
  {
    title: 'VÍDEO',
    url: '/video'
  }
];
