import { useState } from 'react';
import { Dropdown } from './Dropdown';

export const MenuItems = ({ items }) => {
  const path = window.location.pathname;

  const [dropdown, setDropdown] = useState(false);

  return (
    <li className="nav_item">
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
            className="nav_link"
          >
            {items.title} <span className="arrow" />
          </button>
          <Dropdown dropdown={dropdown} submenus={items.submenu} />
        </>
      ) : (
        <a
          href={items.url}
          className={path === items.url ? 'nav_link_active' : 'nav_link'}
        >
          {items.title}
        </a>
      )}
    </li>
  );
};
