import * as React from 'react';
import './NavBar.css';

import { FiMenu } from 'react-icons/fi';
import { FiX } from 'react-icons/fi';
import { items } from './Items';
import { MenuItems } from './MenuItems';

const images = require.context('../../assets/images', true);

export const NavBar = () => {
  const [active, setActive] = React.useState('nav_menu');
  const [toggleIcon, setToggleIcon] = React.useState(true);

  const navToggle = () => {
    active === 'nav_menu'
      ? setActive('nav_menu nav_active')
      : setActive('nav_menu');

    toggleIcon === true ? setToggleIcon(false) : setToggleIcon(true);
  };

  return (
    <div className="nav">
      <div className="nav_logo">
        <a href="/">
          <img src={images(`./logo.png`)} alt="Logo photopiter"></img>
        </a>
      </div>

      <ul className={active}>
        {items.map((menu, index) => {
          return <MenuItems items={menu} key={index} />;
        })}
      </ul>
      <div onClick={navToggle}>
        {toggleIcon ? (
          <FiMenu className="menuItem" />
        ) : (
          <FiX className="menuItem" />
        )}
      </div>
    </div>
  );
};
