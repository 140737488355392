export const Dropdown = ({ submenus, dropdown }) => {
  const path = window.location.pathname;
  return (
    <ul className={`dropdown ${dropdown ? 'show' : ''}`}>
      {submenus.map((submenu, index) => (
        <li key={index} className="nav-submenu-item">
          <a
            href={submenu.url}
            className={path === submenu.url ? 'nav_link_active' : 'nav_link'}
          >
            {submenu.title}
          </a>
        </li>
      ))}
    </ul>
  );
};
