import * as React from 'react';
import { Carousel } from 'react-bootstrap';

import '../home/Home.css';

export const Slider = ({
  images,
  showControls = true,
  showIndicators = true
}) => {
  return (
    <Carousel
      className=""
      interval={3000}
      controls={showControls}
      indicators={showIndicators}
    >
      {images.map((image) => (
        <Carousel.Item key={image.key}>
          <div
            style={{
              backgroundImage: `url('${image.src}')`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '90vh'
            }}
          ></div>
          <Carousel.Caption>
            <h3 className="slider-title">{image.title}</h3>
            {/* <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p> */}
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
